import { API_URL } from 'constants/routes';

import { fetcher } from 'api_entities/fetch';
import { removeFalsy } from 'utils/helpers';

import {
  UserProfile,
  UserProfilePayload,
  ChangePasswordPayload,
  ChangePasswordResult,
  UserUpdateOnboardDataPayload,
} from './types';

const basicParams = {
  scope: 'basic',
  client_secret: 'web-secret',
  client_id: 'web',
};

export const userApi = {
  getUser: async function () {
    const { data } = await fetcher.get<UserProfile>(API_URL.USER);
    return data;
  },
  postUser: async function (payload: Partial<UserProfilePayload>) {
    const newPayload = removeFalsy(payload);
    const { data } = await fetcher.post<UserProfile>(API_URL.USER, newPayload);

    return data;
  },
  updateOnboardData: async function (payload: Partial<UserUpdateOnboardDataPayload>) {
    const newPayload = removeFalsy(payload);
    const { data } = await fetcher.put<UserProfile>(`${API_URL.USER}/onboard`, newPayload, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  },
  changePassword: async function (payload: ChangePasswordPayload) {
    const { data } = await fetcher.put<ChangePasswordResult>(
      API_URL.CHANGE_PASSWORD,
      { ...payload, ...basicParams, grant_type: 'password' },
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    return data;
  },
  closeAccount: async function () {
    await fetcher.delete(API_URL.DELETE_ACCOUNT);
  },
  uploadProfileImg: async function (file: File) {
    const formData = new window.FormData();
    formData.append('file', file, file.name);

    const { data } = await fetcher.post<UserProfile>(`${API_URL.USER}/upload`, formData);

    return data;
  },
};

import styled from 'styled-components';

export const UserMenuContainer = styled.div`
  margin-left: auto;
`;

export const MenuButton = styled.button`
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all ease-in 0.3s;

  background-color: ${({ theme }) => theme.brand.orange};
  color: ${({ theme }) => theme.system.white};

  &:hover {
    cursor: pointer;

    background-color: ${({ theme }) => theme.brand.darkOrange};
    color: ${({ theme }) => theme.system.lightGrey};
  }
`;

export const MenuContainer = styled.div<{ buttonBounds: DOMRect }>`
  min-width: 200px;
  position: fixed;
  overflow: hidden;
  padding: 32px 40px;
  border-radius: 9px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.06);

  top: ${({ buttonBounds }) => buttonBounds.height + buttonBounds.top + 8}px;
  left: ${({ buttonBounds }) => buttonBounds.left + buttonBounds.width}px;
  transform: translateX(-100%);

  background-color: ${({ theme }) => theme.system.white};
`;

export const MenuList = styled.ul`
  color: ${({ theme }) => theme.brand.blue};

  li {
    display: block;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1.2;
  }

  li:not(:last-of-type) {
    margin-bottom: 24px;
  }

  li:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.brand.orange};
  }
`;

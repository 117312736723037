import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  & > div {
    background: ${({ theme }) => theme.system.white};
    border: 1px solid ${({ theme }) => theme.system.lightGrey};
    box-shadow: 0px 16px 16px -8px rgba(17, 17, 17, 0.07);
    border-radius: 6px;
    width: 50%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  & > div:first-child {
    border-right: 0;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > li {
    color: ${({ theme }) => theme.system.darkGreyActive};
    &:first-child > a, div {
      padding-top: 20px;
    }
    & > a, div {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 24px;
      padding-right: 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: ${({ theme }) => theme.system.lightGrey};
        color: ${({ theme }) => theme.brand.blue};
      }
      & > span:first-child {
        margin-right: 9px;
        display: inline-block;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.2;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      & > span:nth-child(2) {
        height: 8px;
        width: 8px;
      }
    }
  }
`;

import { device } from 'constants/device';

import styled from 'styled-components';

export const StyledFooter = styled.footer`
  padding: 60px 100px;
  width: 100%;
  background-color: ${({ theme }) => theme.system.lightGrey};
  height: ${({ theme }) => theme.v2.dimensions.footerHeight};
  @media ${device.laptop} {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 40px;
    padding-bottom: 60px;
    height: initial;
  }
  @media ${device.tablet} {
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 32px;
    padding-bottom: 40px;
    height: initial;
  }
`;

export const Container = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media ${device.laptopL} {
    max-width: ${({ theme }) => theme.v2.dimensions.laptopPageWidth};
  }
  @media ${device.tablet} {
    max-width: 100vw;
    flex-direction: column;
  }
`;

export const LogoContainer = styled.section`
  flex: 1;
  @media ${device.tablet} {
    margin-bottom: 32px;
  }
`;

export const Navigation = styled.section`
  display: flex;
  flex: 2;
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    @media ${device.tablet} {
      padding: 0;
    }
  }
  a {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.2;
    color: ${({ theme }) => theme.system.black};
    &:hover {
      color: ${({ theme }) => theme.brand.blue};
    }
    @media ${device.tablet} {
      font-size: 1.4rem;
    }
  }
  a ~ a {
    margin-top: 20px;
  }
`;

export const SupportContainer = styled.section`
  flex: 1;
  color: ${({ theme }) => theme.system.black};
  @media ${device.tablet} {
    margin-top: 40px;
    margin-bottom: 16px;
  }
  & > h6 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.2;
    margin-bottom: 11px;
    @media ${device.tablet} {
      font-size: 1.4rem;
    }
  }
  & > p {
    font-size: 1.4rem;
    line-height: 160%;
    margin-bottom: 29px;
  }
`;

export const SocialNetworks = styled.div`
  a {
    display: inline-block;
    width: 25px;
    height: 25px;
  }

  color: ${({ theme }) => theme.system.black};
  a ~ a {
    margin-left: 33px;
  }
  svg:hover {
    color: ${({ theme }) => theme.brand.blue};
  }
`;

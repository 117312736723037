import { FC, memo } from 'react';
import { SvgIcon } from 'src/common/SvgIcon';

const ProfileIcon: FC = () => {
  return (
    <SvgIcon viewBox="0 0 20 20" width="20px" height="20px">
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M3.3335 18.3333a6.6666 6.6666 0 1 1 13.3333 0H3.3335Zm6.6667-7.5c-2.7625 0-5-2.2375-5-5 0-2.7626 2.2375-5 5-5s5 2.2374 5 5c0 2.7625-2.2375 5-5 5Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

ProfileIcon.displayName = 'ProfileIcon';

export default memo(ProfileIcon);

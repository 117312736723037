export const CACHE_KEYS = {
  EMAIL: 'email',
  USER: 'user',
  FAVORITES: 'favorites',
  AUTOCOMPLETE: 'autocomplete',
  REVIEWS: 'reviews',
  COMPANIES: 'companies',
  ENTITY_DESCRIPTION: 'entity-description',
  HEADER_MENU: 'header-menu'
};

import { FC, memo, useState, MouseEventHandler } from 'react';
import Link from 'next/link';
import { APP_ROUTES } from 'constants/routes';

import { ProfileIcon } from './components';

import { MenuButton, MenuContainer, MenuList, UserMenuContainer } from './UserMenu.styles';

export type UserMenuProps = {
  onLogoutClick: () => void;
};

const UserMenu: FC<UserMenuProps> = ({ onLogoutClick }) => {
  const [buttonEl, setButtonEl] = useState<HTMLButtonElement | null>(null);

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = ({ currentTarget }) => {
    setButtonEl((prev) => (!prev ? currentTarget : null));
  };

  const closeMenu = () => {
    setButtonEl(null);
  };

  const handleLogOutClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    onLogoutClick();
  };

  return (
    <UserMenuContainer>
      <MenuButton onClick={handleButtonClick}>
        <ProfileIcon />
      </MenuButton>
      {buttonEl && (
        <MenuContainer buttonBounds={buttonEl.getBoundingClientRect()} onMouseLeave={closeMenu}>
          <MenuList>
            <li>
              <Link passHref href={APP_ROUTES.PROFILE}>
                <a>Mijn Account</a>
              </Link>
            </li>
            <li>
              <Link passHref href={APP_ROUTES.LOGOUT}>
                <a onClick={handleLogOutClick}>Log Out</a>
              </Link>
            </li>
          </MenuList>
        </MenuContainer>
      )}
    </UserMenuContainer>
  );
};

export default memo(UserMenu);

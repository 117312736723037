import { COOKIE } from 'constants/cookie';
import { CACHE_KEYS } from 'constants/cache';

import { userApi } from 'api_entities/user';
import Cookie from 'js-cookie';
import { useQuery } from 'react-query';
import { QueryObserverOptions } from 'react-query/types/core/types';

import { UserProfile } from 'api_entities/user/types';

export const useProfile = (options?: Pick<QueryObserverOptions<UserProfile>, 'onSuccess'>) => {
  const isAuthToken = Boolean(Cookie.get(COOKIE.ACCESS_TOKEN) || Cookie.get(COOKIE.REFRESH_TOKEN));

  return useQuery<UserProfile>(CACHE_KEYS.USER, userApi.getUser, {
    enabled: isAuthToken,
    refetchOnMount: true,
    ...(options || {}),
  });
};

import { device } from 'constants/device';

import styled from 'styled-components';

export const Container = styled.header<{ isDark?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: ${({ theme }) => theme.v2.dimensions.headerHeight};
  background-color: ${({ theme, isDark }) => (isDark ? theme.brand.darkBlue : theme.system.white)};
  display: flex;
  align-items: center;
  @media ${device.laptop} {
    height: ${({ theme }) => theme.v2.dimensions.mobileHeaderHeight};
  }
`;

export const Content = styled.div`
  padding: 0 108px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media ${device.laptop} {
    padding: 0 58px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
  }
`;

export const LogoLink = styled.a`
  display: inline-block;
  margin-right: 16px;
  overflow: hidden;
`;

export const NavLink = styled.a<{ isDark?: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${({ theme, isDark }) => (isDark ? theme.brand.blue : theme.system.white)};
  transition: color 0.2s ease-in;

  &:hover {
    color: ${({ theme, isDark }) =>
      isDark ? theme.system.darkGreyActive : theme.system.lightGrey};
  }
`;

export const Navigation = styled.nav`
  flex: 1;
  margin-right: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${device.laptop} {
    display: none;
  }

  ${NavLink} {
    display: inline-block;
    padding: 10px 32px;
  }
`;

export const AuthButtonsContainer = styled.div`
  flex-basis: 214px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.laptop} {
    display: none;
  }
`;

export const LoginLink = styled(NavLink)`
  color: ${({ theme }) => theme.brand.orange};

  &:hover {
    color: ${({ theme }) => theme.brand.darkOrange};
  }
`;

export const SignUpLink = styled(NavLink)`
  position: relative;
  display: flex;
  padding: 10px 32px;
  background-color: ${({ theme }) => theme.brand.orange};
  border-radius: 9px;

  &:hover {
    background-color: ${({ theme }) => theme.brand.darkOrange};
  }

  div {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(20%);
  }
`;

export const MobileMenuButton = styled.div<{ contrast?: boolean }>`
  display: none;
  @media ${device.laptop} {
    display: block;
    button {
      padding: 0;

      svg {
        color: ${({ theme, contrast }) => (contrast ? theme.system.white : theme.system.black)};
      }
    }
  }
`;

export const CategoriesLinkWrapper = styled.div<{ isDark?: boolean }>`
  padding: 20px 32px;
  display: inline-flex;
  align-items: center;
  height: 100%;
  & > p {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${({ theme, isDark }) => (isDark ? theme.brand.blue : theme.system.white)};
    cursor: pointer;
    &:hover {
      color: ${({ theme, isDark }) =>
        isDark ? theme.system.darkGreyActive : theme.system.lightGrey};
    }
  }
`;

export const CategoriesList = styled.div`
  height: 508px;
  width: 560px;
  position: absolute;
  top: ${({ theme }) => theme.v2.dimensions.headerHeight};
`;

export const CategoriesContainer = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.system.black};
  position: relative;
  height: ${({ theme }) => theme.v2.dimensions.headerHeight};
  display: flex;
  align-items: center;
  & > ${CategoriesList} {
    display: none;
  }
  & > ${CategoriesLinkWrapper}:hover ~ ${CategoriesList} {
    display: block;
  }
  & > ${CategoriesList}:hover {
    display: block;
  }
  & > ${CategoriesLinkWrapper}:active ~ ${CategoriesList} {
    display: block;
  }
  & > ${CategoriesList}:active {
    display: block;
  }
`;
